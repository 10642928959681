import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';

function ProgressBlock(props) {
    const {title, description, resultTitle, resultText, percent} = props.item;
    const color = props.item.color();
    const [opened, setOpened] = useState(!(color === 'green'));


    const getType = (color) => {
        switch (color) {
            case 'green':
                return 'success'
            case 'orange':
                return 'warning'
            case 'red':
                return 'danger'
            default:
                return 'success'
        }
    }

    const getIconCode = (color) => {
        switch (color) {
            case 'green':
                return 'fa-check-circle'
            case 'orange':
                return 'fa-exclamation-circle'
            case 'red':
                return 'fa-times-circle'
            default:
                return 'fa-check-circle'
        }
    }


    const toggleOpened = () => {
        setOpened(!opened);
    }

    const CustomToggle = ({ children, eventKey }) => {
        const decoratedOnClick = useAccordionToggle(eventKey, () => {
            toggleOpened();
        });
      
        return (
          <div
            className="accordion-toggle"
            onClick={decoratedOnClick}
          >
            {children}
          </div>
        );
      }



    return(
        <Accordion defaultActiveKey={(color === 'orange' || color === 'red') ? props.eventKey : '-1'}>
            <Card className="audit-block">
                <Card.Header>
                    <CustomToggle eventKey={props.eventKey}>
                        <Typography variant="h5" className="audit-block-title">
                            {/* <i className={`fas ${getIconCode(color)} ${color}`}></i>
                            &nbsp; */}
                            {title}
                        </Typography>
                        <div className="audit-block-header-right">
                            <div className="progress">
                                <div className={`progress-bar bg-${getType(color)}`} role="progressbar" style={{ width: `${percent()}%`}} aria-valuenow={percent()} aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <div className="progress-label">
                                <span>{percent().toFixed(0)}</span>&nbsp;/&nbsp;100
                            </div>
                            <i className={`fas fa-angle-${opened ? 'down' : 'up'}`}></i>
                        </div>
                    </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey={props.eventKey}>
                <Card.Body>
                    {/* <div className="progress" style={{margin: '20px 0px'}}>
                        <div className={`progress-bar bg-${getType(color)}`} role="progressbar" style={{ width: `${percent()}%`}} aria-valuenow={percent()} aria-valuemin="0" aria-valuemax="100"></div>
                    </div> */}

                    {description}
                    <div className={`alert alert-${getType(color)}`}>
                        <h5 className="alert-heading">{resultTitle()}</h5>
                        {resultText()}
                    </div>
                </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>)
}

export default ProgressBlock;