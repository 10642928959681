import React, {useState, useEffect} from 'react';
import axios from 'axios';
import config from './config.json';
import FormStepper from './FormStepper';
import EmailForm from './EmailForm';
import Loading from './Loading';
import Report from './Report';
import Sidebar from './Sidebar';
import { useWindowSize } from './hooks';

// CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'spinkit/spinkit.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import './vendor/icofont.min.css';
import './App.css';

// Material UI
import { createMuiTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

// Images
// import logo from './assets/ranky-logo.png'
import logo_gmaps from './assets/google_maps.png';


//import mockData from './mock.json';


const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: green,
  },
  status: {
    danger: 'orange',
  },
});

const useStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(2, 0),
  },
  title: {
    margin: theme.spacing(0),
    textAlign: 'center',
    lineHeight: 1.6,
    fontSize: '35px',
    fontWeight: 300,
    color: 'white',
    zIndex: 9999999999,
    marginTop: '2rem',
    marginBottom: '3rem',
  },
}));

function getUrlVars() {
  var vars = {};
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
      vars[key] = value;
  });
  return vars;
}


function App() {
  const classes = useStyles();
  const [step, setStep] = useState(0);
  const [inputValues, setInputValues] = useState({
    name: '',
    address: '',
    email: '',
  });
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [prefilled, setPrefilled] = useState(false);
  const size = useWindowSize();

  //MOCK
  // useEffect(() => {
  //   setData(mockData);
  // }, []);

  const handleNext = () => {
    setStep(i => i + 1);
  }

  const setStepperValues = (name, address) => {
    setInputValues({...inputValues, name, address});
    handleNext();
  }

  const setEmailValue = (email) => {
    setInputValues({...inputValues, email});
    handleNext();
  }

  const resetForm = () => {
    setInputValues({
      name: '',
      address: '',
      email: '',
    });
    setStep(0);
    setData(null);
  }

  useEffect(() => {
    const vars = getUrlVars();
    if (vars.name && vars.address) {
      setInputValues({
        name: vars.name,
        address: vars.address,
      });
      setStep(1);
      setPrefilled(true);
    }
  }, []);

  // Launch API request when all inputValues are set
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.post(`${config.apiUrl}/audit`, {
          place : `${inputValues.name}, ${inputValues.address}`,
          name: inputValues.name,
          address : inputValues.address,
          email : inputValues.email,
          prefilled
        });
        setData(result.data);
      } catch (error) {
        setError(error);
        console.log(error);
      }
    };

    if (inputValues.address.length && inputValues.name.length) {
      fetchData();
    }
  }, [inputValues, prefilled]);

  // Reset forms on error
  useEffect(() => {
    if (error) {
      setInputValues({
        name: '',
        address: '',
        email: '',
      });
      setStep(0);
      setPrefilled(false);
    }
  }, [error]);

  // Clear error on new try
  useEffect(() => {
    if (error && (inputValues.name.length))
      setError(null);
  }, [error, inputValues]);
  
  const mainTitleBlock = (
    <Typography variant="h5" className="main-title" gutterBottom className={classes.title}>
      Audit de votre fiche <b style={{fontWeight: 500}}>Google Maps</b>
    </Typography>
  );

  const noData = (
    <div className="row">
      <div className="col-md-12">
        <div className="wrapper">
          {error && <div className="alert alert-danger">Nous n'avons pas trouvé votre Etablissement. Merci de rééssayer.</div>}
          {step === 0 && !data && <FormStepper submit={setStepperValues} />}
          {step === 1 && !data && <Loading />}
        </div>
      </div>
    </div>
  )

  const withData = (
    <div className="row">
      <div className="col-md-12 col-lg-8">
        <div className="wrapper">
          {data && <Report data={Object.assign({}, inputValues, data)} resetFunc={resetForm} />}
        </div>
      </div>
      <Sidebar data={Object.assign({}, inputValues, data)}/>
    </div>
  )

  return (
    <ThemeProvider theme={theme}>
      <section className="pt-8 pt-md-11 pb-10 pb-md-15 bg-primary shape-wrapper">
          <div className="shape shape-top shape-fluid-x svg-shim text-white">
              <svg viewBox="0 0 1738 587" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
              <path d="M0 0H1420.92C1420.92 0 2134.35 457.505 1420.92 485.868C707.502 514.231 0 0 0 0Z" fill="url(#paint0_linear)"></path>
              <defs>
                  <linearGradient id="paint0_linear" x1="0" y1="0" x2="1049.98" y2="912.68" gradientUnits="userSpaceOnUse">
                  <stop stopColor="currentColor" stopOpacity="0.075"></stop>
                  <stop offset="1" stopColor="currentColor" stopOpacity="0"></stop>
                  </linearGradient>
              </defs>
              </svg>
          </div>
      </section>
      <div className="App main-wrapper">
        {mainTitleBlock}

      <div className={size.width <= 991 ? 'container-fluid shape-up' : 'container shape-up'}>
          {!data && noData}
          {data && withData}
      </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
