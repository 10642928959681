import React from 'react';
import SimpleBlock from './SimpleBlock';
import ProgressBlock from './ProgressBlock';
import GlobalScore from './GlobalScore';
import ReportSummary from './ReportSummary';
import Button from '@material-ui/core/Button';

function Report(props) {
    const data = props.data;

    let items = {
        hours : {
            title: 'Horaires',
            value: data.hours,
            description: `Les horaires d'ouverture de l'établissement, à savoir les horaires pendant lesquels vous êtes disponible pour recevoir vos clients permettent à ces derniers d’éviter un déplacement inutile.`,
            resultTitle: data.hours ? 'Vous avez bien configuré les horaires de votre établissement' : 'Vous n\'avez pas configuré les horaires de votre établissement',
            resultText: data.hours ? <p>Pour les jours où vous ouvrez à des heures inhabituelles, durant les périodes de vacances ou à l'occasion d'événements spéciaux, par exemple, vous pouvez définir des horaires d'ouverture exceptionnels.</p> : <p>Rendez-vous dans l'onglet infos de votre compte Google My Business pour renseigner vos horaires d’ouverture.</p>
        },
        website : {
            title: 'Site Web',
            value: data.website,
            description: `Votre fiche Google My Business ne se substitue pas à votre site internet. Lier un site internet à votre fiche Google, vous permet de mettre à disposition des informations essentielles pour vos consommateurs (produits/services disponibles, prix …)`,
            resultTitle: data.website ? 'Vous avez bien configuré votre site Web' : 'Vous n\'avez pas configuré le site Web de votre Etablissement',
            resultText: data.website 
            ? <p>N’oubliez pas d’actualiser vos informations et de respecter les bonnes pratiques SEO ! Rendez vous sur votre <a target="_blank" rel="noopener noreferrer" className="alert-link" href="https://support.google.com/business/answer/7178589">interface Google My Business</a> pour mettre à jour votre contenu.</p> 
            : <p>Créer gratuitement votre site vitrine <a target="_blank" rel="noopener noreferrer" className="alert-link" href="https://support.google.com/business/answer/7178589">sur Google My Business</a> en quelques clics ! </p>
        },
        vr: {
            title: 'Visite Virtuelle',
            value: data.vr,
            description: `Mettre en place une visite virtuelle sur sa fiche Google My Business permet d’augmenter le temps passé par les utilisateurs sur votre fiche Google.`,
            resultTitle: data.vr ? 'Vous avez bien configuré la visite virtuelle de votre Etablissement' : 'Vous n\'avez pas configuré la visite virtuelle de votre Etablissement',
            resultText: data.vr 
            ? <p>Vous pouvez proposer plus de contenu en 360° et actualiser les prises de vues régulièrement.</p> 
            : <p>Téléchargez l’application Street View 360 sur le <a target="_blank" rel="noopener noreferrer" className="alert-link" href="https://play.google.com/store/apps/details?id=com.google.android.street&hl=fr">Google Play Store</a> ou l'<a target="_blank" rel="noopener noreferrer" className="alert-link" href="https://apps.apple.com/ch/app/google-street-view/id904418768?l=fr">App Store d'Apple</a> pour prendre des photos à 360° de votre établissement ou contacter <a target="_blank" rel="noopener noreferrer" className="alert-link" href="https://www.google.com/intl/fr/streetview/contacts-tools/#sv-pro">un professionnel agréé</a> .</p>
        },
        phone: {
            title: 'Numéro de Téléphone',
            value: data.tel,
            description: `La grande majorité des recherches sont effectuées sur Mobile, les utilisateurs peuvent directement vous appeler à partir de votre fiche Google pour vous poser une question ou réserver chez vous par exemple.`,
            resultTitle: data.tel ? 'Vous avez bien configuré un numéro de téléphone pour votre Etablissement' : 'Vous n\'avez pas configuré de numéro de téléphone pour votre Etablissement',
            resultText: data.tel 
            ? <p>Votre numéro de téléphone apparaît bien sur votre fiche Google Maps. Vous pouvez suivre le nombre d’appels générés depuis votre fiche Google Maps sur votre interface Google My Business.</p> 
            : <p>Rendez-vous dans <a target="_blank" rel="noopener noreferrer" className="alert-link" href="https://support.google.com/business/answer/3039617">l'onglet infos</a> de votre compte Google My Business pour renseigner votre numéro de téléphone.</p>
        },
        owned: {
            title: 'Propriété de l\'Etablissement',
            value: !data.ownable,
            description: `Revendiquer son établissement permet d’accéder via l’interface Google My Business à toutes les informations concernant votre fiche Google.`,
            resultTitle: !data.ownable ? 'L\'Etablissement est relié à un compte Google.' : 'L\'Etablissement n\'est pas relié à un compte Google.',
            resultText: !data.ownable 
            ? <p>Votre établissement a bien été revendiqué. Si vous n’êtes pas le propriétaire de votre fiche Google My Business, vous pouvez suivre <a target="_blank" rel="noopener noreferrer" className="alert-link" href="https://ranky.io/recuperer-la-propriete-de-votre-fiche-google-my-business/">ce tutoriel.</a> pour revendiquer votre établissement.</p> 
            : <p>Vérifiez que vous êtes bien propriétaire de cet établissement, pour revendiquer votre établissement vous pouvez suivre <a target="_blank" rel="noopener noreferrer" className="alert-link" href="https://ranky.io/recuperer-la-propriete-de-votre-fiche-google-my-business/">ce tutoriel.</a></p>
        },
        action : {
            title: 'Bouton d\'action Google Maps',
            value: data.actions,
            description: `Les liens de réservation permettent à vos clients de réserver vos services directement sur la fiche Google Maps, ils bénéficient également de l'intégration avec les produits Google les plus utilisés, tels que Google Agenda, ainsi que des rappels automatiques pour ne pas manquer leur rendez-vous.`,
            resultTitle: data.actions ? 'La réservation est bien activée sur votre fiche Google Maps.' : 'La réservation n\'est pas activée sur votre fiche Google Maps.',
            resultText: data.actions 
            ? <p>Félicitations, votre bouton d’action est bien configuré.</p> 
            : <p>Afin d’améliorer vos taux d'engagement, vous pouvez mettre en place un bouton d’action en suivant <a target="_blank" rel="noopener noreferrer" className="alert-link" href="https://ranky.io/recuperer-la-propriete-de-votre-fiche-google-my-business/">cette page</a>.</p>
        },
        tags : {
            title: 'Attributs',
            value: data.tags,
            description: `Il existe deux types d'attributs : les attributs factuels (tables en terrasse ou établissement géré par des femmes, par exemple) et d'autres, de nature subjective (la popularité de votre établissement auprès de la clientèle locale, par exemple). Vous pouvez modifier directement certains attributs factuels. Les attributs subjectifs, quant à eux, dépendent des avis Google.`,
            resultTitle: data.tags ? 'Des attributs sont présents sur votre fiche Google Maps.' : 'Aucun attribut n\'est présent sur votre fiche Google Maps.',
            resultText: data.tags 
            ? <p>Vous pouvez générer automatiquement plus d’attributs subjectifs en augmentant votre quantité d’avis Google. Vous pouvez automatiser le recueil d’avis Google avec <a target="_blank" rel="noopener noreferrer" className="alert-link" href="https://ranky.io/notre-produit/">Ranky</a>.</p> 
            : <p>Rendez-vous dans votre onglet infos sur Google My Business pour gérer vos attributs factuels. Collectez plus d’avis Google afin de générer automatiquement des attributs subjectifs. Vous pouvez automatiser le recueil d’avis Google avec <a target="_blank" rel="noopener noreferrer" className="alert-link" href="https://ranky.io/notre-produit/">Ranky</a>.</p>
        },
        recent_reviews : {
            title: 'Avis Récents',
            value: data.recent_reviews,
            percent: () => { return data.recent_reviews * 10},
            color: () => {
                if (data.recent_reviews < 2) {
                    return 'red';
                } else if (data.recent_reviews < 10) {
                    return 'orange';
                } else {
                    return 'green';
                }
            },
            description: 
            <p>
                Google accorde beaucoup d’importance à la fréquence et à la fraîcheur des avis, il faut donc en collecter régulièrement et en quantité. 
                <br/>
                <br/>
                Il faut créer un maximum d’interaction entre vos clients et votre fiche Google Maps.
            </p>,
            resultTitle: () => {
                if (!data.recent_reviews) {
                    return 'Vous n’avez aucun nouvel avis Google sur les 7 derniers jours.';
                } else if (data.recent_reviews  === 1) {
                    return 'Vous n’avez qu’un nouvel avis Google sur les 7 derniers jours.';
                } else if (data.recent_reviews >= 2 && data.recent_reviews <= 3){
                    return `Vous n’avez que ${data.recent_reviews} nouveaux avis Google sur les 7 derniers jours.`;
                } else if (data.recent_reviews >= 4 && data.recent_reviews <= 9){
                    return `Vous avez gagné ${data.recent_reviews} nouveaux avis Google sur les 7 derniers jours.`;
                } else {
                    return 'Vous avez gagné plus de 10 avis Google sur les 7 derniers jours.';
                }
            },
            resultText: () => {
                if (!data.recent_reviews) {
                    return <p>{'Vous devriez inciter d’avantages vos clients à laisser des avis sur votre établissement.'} Simplifiez vous l'avis avec <a target="_blank" rel="noopener noreferrer" className="alert-link" href="https://ranky.io/notre-produit/">Ranky</a></p>;
                } else if (data.recent_reviews  === 1) {
                    return <p>{'Vous devriez inciter d’avantages vos clients à laisser des avis sur votre établissement.'} Simplifiez vous l'avis avec <a target="_blank" rel="noopener noreferrer" className="alert-link" href="https://ranky.io/notre-produit/">Ranky</a></p>;
                } else if (data.recent_reviews >= 2 && data.recent_reviews <= 3){
                    return <p>{`Vous devriez inciter d’avantages vos clients à laisser des avis sur votre établissement.`} Simplifiez vous l'avis avec <a target="_blank" rel="noopener noreferrer" className="alert-link" href="https://ranky.io/notre-produit/">Ranky</a></p>;
                } else if (data.recent_reviews >= 4 && data.recent_reviews <= 9){
                    return <p>{`Gardez le rythme !`} Simplifiez vous l'avis avec <a target="_blank" rel="noopener noreferrer" className="alert-link" href="https://ranky.io/notre-produit/">Ranky</a></p>;
                } else {
                    return 'Vous êtes un maître Jedi de la collecte des avis Google !';
                }
            },
        },
        reviews : {
            title: 'Nombre d\'avis',
            value: data.reviews,
            percent: () => {
                const nb = parseInt(data.reviews.replace('\u202f', ''));
                if (nb > 5000) {
                    return 100;
                } else if (nb >= 1900 && nb <= 5000) {
                    return 90;
                } else if (nb >= 0 && nb <= 300) {
                    return nb / 10;
                } else {
                    return (nb * (60 / 1600));
                }
            },
            color: () => {
                const nb = parseInt(data.reviews.replace('\u202f', ''));
                if (nb < 300) {
                    return 'red';
                } else if (nb < 1900) {
                    return 'orange';
                } else {
                    return 'green';
                }
            },
            description: 
            <p>
                <b>72% des consommateurs</b> vont effectuer une action après avoir lu un avis positif (source Brightlocal).
                <br/>
                <br/>
                Le nombre d’avis de votre fiche Google My Business influence directement votre classement sur Google Maps.
            </p>,
            resultTitle: () => {
                return `Vous avez ${data.reviews} avis Google`;
            },
            resultText: () => {
                const nb = parseInt(data.reviews.replace('\u202f', ''));

                if (nb >= 1900) {
                    return 'Bravo ! Vous êtes incontournable sur Google Maps.';
                } else if (nb >= 0 && nb <= 300) {
                    return <p>{'Le nombre d’avis Google est faible, les internautes accordent plus de confiance envers un établissement qui affiche plus de 300 avis. Vous pouvez vous aider de'} <a target="_blank" rel="noopener noreferrer" className="alert-link" href="https://ranky.io/notre-produit/">notre solution</a> {'pour collecter 10 fois plus d’avis Google tous les mois et améliorer votre position sur Google Maps.'}</p>;
                } else {
                    return <p>{'Vous êtes sur le bon chemin, en continuant à collecter des avis Google, vous pouvez devenir un point d’interaction fort sur Google Maps et apparaître systématiquement sur la carte. Vous pouvez vous aider de'} <a target="_blank" rel="noopener noreferrer" className="alert-link" href="https://ranky.io/notre-produit/">notre solution</a> {'pour collecter 10 fois plus d’avis Google tous les mois et atteindre cet objectif.'}</p>;
                }
            },
        },
        rating : {
            title: 'Notation Google',
            value: data.rating,
            percent: () => {
                const rating = data.rating.replace(',', '.');
                const nb = rating * 20;
                if (nb === 100)
                    return 10;
                return nb;
            },
            color: () => {
                const rating = parseFloat(data.rating.replace(',', '.'));
                if (rating === 5 || (rating >= 0 && rating <= 2.9)) {
                    return 'red';
                } else if (rating >= 3 && rating <= 4.4) {
                    return 'orange';
                } else {
                    return 'green';
                }
            },
            description: 
            <p>
            Votre note représente l'indice de confiance des utilisateurs.
            <br/>
            <br/>
            Votre évaluation Globale va être comparée à celle de vos concurrents par Google et par les internautes. Elle va définir votre classement dans les résultats de recherche Google. 
            </p>,
            resultTitle: () => {
                return `Votre notation Google est de ${data.rating}`;
            },
            resultText: () => {
                const rating = parseFloat(data.rating.replace(',', '.'));
                if (rating >= 0 && rating <= 2.9) {
                    return <p>{'Vous pouvez améliorer l’expérience client dans votre établissement. Automatisez la relance vers la rédaction d’avis Google auprès de vos clients satisfaits avec '} <a target="_blank" rel="noopener noreferrer" className="alert-link" href="https://ranky.io/notre-produit/">notre tablette intelligente</a>.</p>;
                } else if (rating >= 3 && rating <= 4.4) {
                    return <p>{'Vos clients satisfaits ne laissent pas systématiquement un avis Google. Automatisez la relance vers la rédaction d’avis Google auprès de vos clients satisfaits avec '} <a target="_blank" rel="noopener noreferrer" className="alert-link" href="https://ranky.io/notre-produit/">notre tablette intelligente</a>.</p>;
                } else if (rating >= 4.5 && rating <= 4.9) {
                    return <p>{'Vos clients sont satisfaits de vos services, vous pouvez facilement améliorer votre positionnement dans les résultats de recherche Google en augmentant votre niveau d’interaction client. Automatisez la relance vers la rédaction d’avis Google auprès de vos clients satisfaits avec '} <a target="_blank" rel="noopener noreferrer" className="alert-link" href="https://ranky.io/notre-produit/">notre tablette intelligente</a>.</p>;
                } else {
                    return 'Votre note est trop élevée, aucune entreprise ne peut satisfaire 100 % de ses clients. Une note de 5/5 a une crédibilité très faible auprès des consommateurs.';
                }
            },
        },
        pic_cat : {
            title: 'Photos',
            value: data.pic_cat,
            percent: () => {
                const res = data.pic_cat * (100 / 7);
                if (res > 100)
                    return 100;
                return res;
            },
            color: () => {
                const nb = data.pic_cat;
                if (nb >= 0 && nb <= 2) {
                    return 'red';
                } else if (nb >= 3 && nb <= 5) {
                    return 'orange';
                } else {
                    return 'green';
                }
            },
            description: 
            <p>
                Les entreprises avec des photos sur leurs profils reçoivent 42% de plus de demandes d’informations sur Google Maps et leur taux de clic vers leur site Web est 35% supérieur aux profils d’entreprise sans photos, selon Google. Il existe entre 8 et 9 catégories de photos suivant votre établissement.
                <br/>
                <br/>
                Ces photos peuvent inclure les biens et/ou services offerts par votre entreprise, le personnel de l’entreprise travaillant et/ou aidant les clients, l’intérieur et l’extérieur de l’entreprise, et d’autres photos générales qui résument l’entreprise et ce qu’elle est en mesure de faire pour ses clients.
            </p>,
            resultTitle: () => {
                const nb = data.pic_cat;
                if (nb >= 0 && nb <= 2) {
                    return `Vous n’avez renseigné que ${data.pic_cat} catégories de photos sur Google My Business.`;
                } else if (nb >= 3 && nb <= 5) {
                    return `Votre fiche Google My Business comprend ${data.pic_cat} catégories de photos.`;
                } else {
                    return `Votre fiche Google My Business comprend ${data.pic_cat} catégories de photos.`;
                }
            },
            resultText: () => {
                const nb = data.pic_cat;
                if (nb >= 0 && nb <= 2) {
                    return `Vous pouvez ajouter votre Logo, une nouvelle photo de couverture, des photos de l’équipe et autres.`;
                } else if (nb >= 3 && nb <= 5) {
                    return 'Vous êtes sur le bon chemin, n’oubliez pas d’actualiser votre contenu régulièrement !';
                } else {
                    return `Vous avez bien renseigné toutes les typologies de photos demandées par Google, n’oubliez pas d’actualiser votre contenu régulièrement !`;
                }
            },
        },
        

    
    }


    return(
        <div>
            <GlobalScore items={items} data={data} />
            <h5 className="report-section-title">
                Votre audit en résumé
            </h5>
            <ReportSummary rating={items.rating.value} reviews={items.reviews.value} recents={items.recent_reviews.value} />
            <h5 className="report-section-title">
                Votre audit en détail
            </h5>

            <SimpleBlock item={items.website} eventKey={1}/>
            <ProgressBlock item={items.rating} eventKey={2}/>
            <ProgressBlock item={items.reviews} eventKey={3}/>
            <ProgressBlock item={items.recent_reviews} eventKey={4}/>
            <SimpleBlock item={items.vr} eventKey={5}/>
            <ProgressBlock item={items.pic_cat} eventKey={6}/>
            <SimpleBlock item={items.hours} eventKey={7}/>
            <SimpleBlock item={items.phone} eventKey={8}/>
            <SimpleBlock item={items.owned} eventKey={9}/>
            {/* <SimpleBlock item={items.action} eventKey={10}/>
            <SimpleBlock item={items.tags} eventKey={11}/> */}

            <a href="https://ranky.io/notre-produit/" target="_blank" rel="noopener noreferrer"  className="btn btn-primary action-btn green-bg">
                Améliorer ma visibilité
            </a>
            <Button
                variant="contained"
                color="primary"
                style={{display: 'block', margin: ' 20px auto'}}
                onClick={props.resetFunc}
            >
                Auditer un autre Etablissement
            </Button>

        </div>
    );
}

export default Report;