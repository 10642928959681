import React from 'react';

function GlobalScore(props) {
    const {items, data } = props;

    let score = 0;
    score = (() => {
        let res = 0;
        if (props.items) {
            if (items.website.value) {
                res += 90;
            }
            if (items.vr.value) {
                res += 90;
            }
            if (items.hours.value) {
                res += 90;
            }
            if (items.phone.value) {
                res += 100;
            }
            if (items.owned.value) {
                res += 100;
            }
            res += items.rating.percent();
            res += items.reviews.percent() * 0.9;
            res += items.recent_reviews.percent();
            res += items.pic_cat.percent();
        }
        return (res * 100) / 860;
    })();

    const getBlockColor = (percent) => {
        if (percent < 50)
            return 'red';
        else if (percent < 80) {
            return 'orange';
        } else {
            return 'green';
        }
    }

    return(
        <div className="global-score">
            <div className="row">
                <div className="col-sm-8">
                    <h5 className="place-name blue">
                        {decodeURI(data.name)}
                        <br />
                    </h5>
                    <div className="address-block">
                        <i className="fas fa-map-marker-alt" style={{color: '#767676'}}></i>
                        <span className="address ">
                            {decodeURI(data.address)}
                        </span>
                    </div>
                </div>
                <div className="col-sm-4">
                    <p className="score-block-label">Votre note</p>
                    <div className="score-block-wrapper">
                        <div className={`score-block ${getBlockColor(score)}-bg`}>
                            <h3 className={`score `}>
                                {score.toFixed(0)} <span>/ 100</span>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GlobalScore;