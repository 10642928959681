import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';


const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  }));


function FormStepper(props) {
    const [activeStep, setActiveStep] = React.useState(0);
    const [inputValues, setInputValues] = useState({
        name: '', address: ''
      });
      
    const handleOnChange = event => {
        const { name, value } = event.target;
        setInputValues({ ...inputValues, [name]: value });
    };
      
    const classes = useStyles();

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
        if (activeStep > 0) {
            setTimeout(() => {
                props.submit(inputValues.name, inputValues.address);
            }, 1000);
        }
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    return (
        <div>
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <div className={classes.root}>
                    <Stepper activeStep={activeStep} orientation="vertical">
                        <Step key={'name'}>
                            <StepLabel>Nom de votre établissement</StepLabel>
                            <StepContent>
                            <TextField
                                id="standard-basic"
                                placeholder="Ex : Pizzeria Gusto ou Pharmacie des Lilas"
                                fullWidth
                                margin="normal"
                                name="name"
                                onChange={handleOnChange}
                                value={inputValues.name}
                                type="text"
                            />

                            <div className={classes.actionsContainer}>
                                <div>
                                <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    className={classes.button}
                                >
                                    Retour
                                </Button>
                                <Button
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    onClick={handleNext}
                                    className={classes.button}
                                    disabled={inputValues.name.length === 0}
                                >
                                    Suivant
                                </Button>
                                </div>
                            </div>
                            </StepContent>
                        </Step>
                        <Step key={'name'}>
                            <StepLabel>Adresse de l'établissement</StepLabel>
                            <StepContent>
                            <FormControl fullWidth>
                                <Input
                                    name="address"
                                    onChange={handleOnChange}
                                    value={inputValues.address}
                                />
                                <FormHelperText id="component-helper-text">Au format 'Adresse, ville' par exemple : 14 Rue Montmartre, Paris</FormHelperText>
                            </FormControl>

                            <div className={classes.actionsContainer}>
                                <div>
                                <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    className={classes.button}
                                >
                                    Retour
                                </Button>
                                <Button
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    onClick={handleNext}
                                    className={classes.button}
                                    disabled={inputValues.address.length === 0}
                                >
                                    Valider
                                </Button>
                                </div>
                            </div>
                            </StepContent>
                        </Step>

                    </Stepper>
                </div>
            </form>

        </div>
    );
}

export default FormStepper;