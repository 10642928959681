import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';

function SimpleBlock(props) {
    const item = props.item;
    let title, value, description, resultTitle, resultText;
    
    if (item) {
        title = item.title;
        value = item.value;
        description = item.description;
        resultTitle = item.resultTitle;
        resultText = item.resultText;
    }
    const [opened, setOpened] = useState(!value);

    const toggleOpened = () => {
        setOpened(!opened);
    }

    const CustomToggle = ({ children, eventKey }) => {
        const decoratedOnClick = useAccordionToggle(eventKey, () => {
            toggleOpened();
        });
      
        return (
          <div
            className="accordion-toggle"
            onClick={decoratedOnClick}
          >
            {children}
          </div>
        );
      }
    
    if (title !== undefined && value !== undefined && description !== undefined && resultTitle !== undefined && resultText !== undefined) {
        return(
            <Accordion defaultActiveKey={!value ? props.eventKey : '-1'}>
                <Card className="audit-block">
                    <Card.Header>
                        <CustomToggle eventKey={props.eventKey}>
                            <Typography variant="h5" className="audit-block-title">
                                {/* <i className={`fas ${getIconCode(color)} ${color}`}></i>
                                &nbsp; */}
                                {title}
                            </Typography>
                            <div className="audit-block-header-right">
                                <div className="progress">
                                    <div className={`progress-bar bg-success`} role="progressbar" style={{ width: `${value ? 100 : 0}%`}} aria-valuenow={value ? 100 : 0} aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <div className="progress-label">
                                    <span>{value ? '100' : '0'}</span>&nbsp;/&nbsp;100
                                </div>
                                <i className={`fas fa-angle-${opened ? 'down' : 'up'}`}></i>
                            </div>
                        </CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={props.eventKey}>
                    <Card.Body>
                        <p>
                            {description}
                        </p>
                        <div className={`alert ${value ? 'alert-success' : 'alert-danger'}`}>
                            <h5 className="alert-heading">{resultTitle}</h5>
                            {resultText}
                        </div>
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>)
    }
    return null;
}

export default SimpleBlock;