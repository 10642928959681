import React from 'react';


import icon1 from './assets/icon-1.png';
import icon2 from './assets/icon-2.png';
import icon3 from './assets/icon-3.png';

function ReportSummary(props) {
    const { rating, reviews, recents } = props;

    let ratingColor = 'green';
    if (rating === 5 || (rating >= 0 && rating <= 2.9)) {
        ratingColor = 'red';
    } else if (rating >= 3 && rating <= 4.4) {
        ratingColor = 'orange';
    } else {
        ratingColor = 'green';
    }

    let reviewColor = 'green';
    if (reviews < 300) {
        reviewColor = 'red';
    } else if (reviews < 1900) {
        reviewColor = 'orange';
    } else {
        reviewColor = 'green';
    }

    let recentsColor = 'green';
    if (recents < 2) {
        recentsColor = 'red';
    } else if (recents < 10) {
        recentsColor = 'orange';
    } else {
        recentsColor = 'green';
    }



    return(
        <div className="report-summary">
            <div className="row">


                <div className="col-sm-4 summary-block-wrapper">
                    <div className="summary-block shadowed">
                        <img src={icon1} alt="Note Google" className="summary-block-pic"/>
                        <div className="summary-block-title">
                            Votre note Google
                        </div>
                        <div className={`summary-block-value ${ratingColor}`}>
                            {rating}
                        </div>
                    </div>
                </div>
                <div className="col-sm-4 summary-block-wrapper">
                    <div className="summary-block shadowed">
                        <img src={icon3} alt="Avis Google" className="summary-block-pic"/>
                        <div className="summary-block-title">
                            Nombre d'avis
                        </div>
                        <div className={`summary-block-value ${reviewColor}`}>
                            {reviews}
                        </div>
                    </div>
                </div>
                <div className="col-sm-4 summary-block-wrapper">
                    <div className="summary-block shadowed">
                        <img src={icon2} alt="Avis récents Google" className="summary-block-pic"/>
                        <div className="summary-block-title">
                            Avis récents
                        </div>
                        <div className={`summary-block-value ${recentsColor}`}>
                            {recents}
                        </div>
                    </div>
                </div>



            </div>
            <div className="row">
                <div className="col-md-12">
                    <h4 className="report-summary-incentive">
                        Découvrez <a href="https://ranky.io/notre-produit/" target="_blank" rel="noopener noreferrer">notre solution</a> et collectez plus d'avis positifs
                    </h4>
                </div>
            </div>
        </div>
    );
}

export default ReportSummary;