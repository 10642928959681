import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import {useInterval} from './hooks';


const getMessage = (i) => {
    const loadingMessage = [
        'Analyse de votre réputation en ligne',
        'Génération d\'un rapport',
        'Cela peut prendre quelques minutes'
    ];

    if (i < loadingMessage.length) {
        return loadingMessage[i];
    } else {
        return loadingMessage[loadingMessage.length - 1];
    }
}

function Loading() {
    const [index, setIndex] = useState(0);



    useInterval(() => {
        setIndex(i => i + 1);
    }, 10000);

    return(
        <div>
            <div className="loading-spinner sk-grid sk-center">
                <div className="sk-grid-cube"></div>
                <div className="sk-grid-cube"></div>
                <div className="sk-grid-cube"></div>
                <div className="sk-grid-cube"></div>
                <div className="sk-grid-cube"></div>
                <div className="sk-grid-cube"></div>
                <div className="sk-grid-cube"></div>
                <div className="sk-grid-cube"></div>
                <div className="sk-grid-cube"></div>
            </div>
            <Typography variant="h5" gutterBottom className="text-center" style={{marginTop: '30px'}}>
                {getMessage(index)}
            </Typography>

        </div>
    );
}

export default Loading;