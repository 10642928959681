import React from 'react';
import logo from './assets/logo-blue.png';

export default function Sidebar (props) {
    const { data } = props;
    const rating = parseFloat(data.rating.replace(',', '.'));

    const getPredFreq = () => {
        if (data.reviews < 100)
            return [130, 180];
        else if ( data.reviews < 300)
            return [80, 130];
        else
            return [50, 80];
    }

    const getPredRecents = () => {
        if (data.recent_reviews === 0 || data.recent_reviews === 1)
            return [8, 16];
        else 
            return [Math.floor(data.recent_reviews * 9), Math.floor(data.recent_reviews * 15)];
    }

    const getPredRating = () => {
        const rating = parseFloat(data.rating.replace(',', '.'));
        if (rating < 4)
            return rating + 0.5;
        else if (rating >= 4 && rating < 4.5) 
            return rating + 0.3;
        else if (rating === 4.9)
            return 4.9;
        else if (rating === 5)
            return 5;
        else
            return rating + 0.1
    }

    return (
        <div className="col-md-12 col-lg-4 sidebar">
            <div className="wrapper">
                {/* <img className="sidebar-logo" src={logo} alt="Ranky"/> */}
                <h5 className="top-text">
                    Essayez <b className="blue" style={{ fontWeight: 600 }}>notre solution</b> sans engagement et améliorez votre visiblité.
                    <br/>
                </h5>

                {/* <div className="comp-block">
                    <p className="comp-title">Nouveaux avis*</p>
                    <div className="comp-numbers">
                        <p className="comp-number-label">de&nbsp;&nbsp;</p>
                        <p className="comp-number green">{getPredRecents()[0]}</p>
                        <p className="comp-number-label">&nbsp;&nbsp;à&nbsp;&nbsp;</p>
                        <p className="comp-number green">{getPredRecents()[1]}</p>
                        <p className="comp-number-label">&nbsp;&nbsp;par mois</p>
                    </div>
                </div>
                <div className="comp-block">
                    <p className="comp-title">Votre note Google Maps*</p>
                    <div className="comp-numbers">
                        {rating !== 4.9 && <p className="comp-number-label">montez&nbsp;&nbsp;à&nbsp;&nbsp;</p>}
                        {rating === 4.9 && <p className="comp-number-label">restez&nbsp;&nbsp;à&nbsp;&nbsp;</p>}
                        <p className="comp-number green">{getPredRating().toString().replace('.', ',')}</p>
                        {rating !== 4.9 && <p className="comp-number-label">&nbsp;&nbsp;en 3 mois</p>}

                    </div>
                </div>
                <div className="comp-block">
                    <p className="comp-title">Nombre de vues*</p>
                    <div className="comp-numbers">
                        <p className="comp-number-label">de&nbsp;&nbsp;</p>
                        <p className="comp-number green">+{getPredFreq()[0]}%</p>
                        <p className="comp-number-label">&nbsp;&nbsp;à&nbsp;&nbsp;</p>
                        <p className="comp-number green">+{getPredFreq()[1]}%</p>
                    </div>
                </div> */}
                <div className="new-comp-block">
                    <div className="comp-label">
                        Nouveaux avis
                        <span>par mois*</span>
                    </div>
                    <div className="comp-value">
                        <span>+</span>
                        {getPredRecents()[1]}
                    </div>
                </div>
                <div className="new-comp-block">
                    <div className="comp-label">
                        Note Google Maps
                        <span>en 3 mois*</span>
                    </div>
                    <div className="comp-value">
                        {getPredRating().toFixed(1).toString().replace('.', ',')}
                        <span>/5</span>
                    </div>
                </div>
                <div className="new-comp-block">
                    <div className="comp-label">
                        Nombre de vues
                        <span>de votre page Google Maps*</span>
                    </div>
                    <div className="comp-value">
                        <span>+</span>
                        {getPredFreq()[1]}
                        <span>%</span>
                    </div>
                </div>
                <p className="disclaimer">
                    * données calculées sur la base de votre audit et sur les performances réalisées auprès de nos clients actuels
                </p>


                {/* <div className="comp-block">
                    <p className="comp-title">Vous êtes sur TripAdvisor ?</p>
                    <div className="comp-text-wrapper">
                        <p className="comp-text ">
                            <i className="fab fa-tripadvisor" style={{ fontSize: '35px'}}></i>
                            &nbsp;
                            C'est inclus
                        </p>
                    </div>
                </div> */}


                <a href="https://ranky.io/tarifs" target="_blank"  rel="noopener noreferrer" className="btn btn-block btn-primary action">
                    Découvrez nos tarifs
                </a>
                {/* <hr /> */}

                {/* <div className="block-items">
                    <div className="block first">
                        <i className="icon icofont-lock"></i>
                        <span className="label">Paiement 100% sécurisé</span>
                    </div>
                    <div className="block second">
                        <i className="icon icofont-ui-calendar"></i>
                        <span className="label">30 jours pour changer d'avis</span>
                    </div>
                    <div className="block third">
                        <i className="icon fas fa-shipping-fast"></i>
                        <span className="label">Livraison gratuite</span>
                    </div>
                    <div className="block fourth">
                        <i className="icon icofont-link-broken"></i>
                        <span className="label">Sans engagement</span>
                    </div>
                </div> */}
            </div>
        </div>
    )
}